<template>
  <div class="page" :class="{'is-mobile' : $vuetify.breakpoint.mobile}">
    <div class="content">
      <v-card class="ma-0 ma-sm-4 d-flex flex-grow-1 flex-column" min-height="500">
        <div class="flex-grow-1 flex-sm-grow-0 forms-content overflow-auto">
          <section class="pa-4">
            <v-row>
              <v-col col="12">
                <v-form ref="form" @submit.prevent="processarRetorno" v-model="formValid" lazy-validation>
                  <v-file-input
                    accept=".ret"
                    label="Escolha o arquivo de retorno"
                    v-model="arquivoRetorno"
                    :rules="[
                      this.$validators.string.required,
                    ]"
                    ></v-file-input>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <v-btn class="ma-1" color="primary" @click="processarRetorno" :disabled="!formValid">Processar Arquivo</v-btn>
                <v-btn class="ma-1" color="primary" @click="imprimirLogProcessamentoRetorno" v-show="logProcessamento.linhas.length > 0">Imprimir Processamento</v-btn>
              </v-col>
            </v-row>
          </section>
          <!--v-divider/>
          <section v-show="logProcessamento.linhas.length > 0">
            <v-progress-linear
              indeterminate
              color="cyan"
              v-show="loadingProcessamento"
            ></v-progress-linear>
            <v-data-table
              :headers="headers"
              :disable-sort="true"
              :items="logProcessamento.linhas"
              :items-per-page="5"
              :footer-props="{
                disableItemsPerPage: true,
                itemsPerPageOptions: [6],
              }"
            >
            </v-data-table>
          </section-->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import {ApiError} from "../../../api/core";
import {processarRetornoBanco} from '../../../api/faturas';
import {downloadLogProcessamentoRetorno} from "../../../api/relatorios";
import * as moment from 'moment';

export default {
  name: "index",
  data() {
    return {
      logProcessamento: {
        arquivo: "",
        linhas: []
      },
      arquivoRetorno: null,
      formValid: false,
      loadingProcessamento: false,
      searchColumns: [],
      headers: [
        {
          value: 'linha',
          text: 'Linha',
          align: 'start',
          width: "5%"
        },
        {
          value: 'conteudo',
          text: 'Conteudo',
          align: 'start',
        },
      ]
    }
  },
  methods: {
    async processarRetorno() {
      try {
        this.loadingProcessamento = true;
        this.logProcessamento.linhas = [];
        if (this.arquivoRetorno.name !== ""){
          const retorno = await processarRetornoBanco(this.arquivoRetorno);
          this.logProcessamento = retorno.logProcessamento;
          this.$toast.success(retorno.msg, {
            timeout: 3000
          })
        } else {
          this.$toast.error(`Selecione um arquivo de retorno para o processamento`, {
            timeout: 6000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível processar o arquivo.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loadingProcessamento = false;
      }
    },
    async imprimirLogProcessamentoRetorno() {
      this.loadingProcessamento = true;
      let item = {
        formatoSaida: "pdf",
        arquivo: this.logProcessamento.arquivo,
      };
      await downloadLogProcessamentoRetorno(item);
      this.loadingProcessamento = false;
    },
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}
.page .content {
  /*border:2px solid green;*/
  overflow: auto;
  max-height: calc(100vh - 50px);
  width: 100%;
  flex: 1;

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 157px);
    }
  }
}
</style>